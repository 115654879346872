<template>
  <div class="site-view">
    <div v-if="!state.token" class="login">
      <div class="login-row">
        <label for="username">Username:</label>
        <input type="text" id="username" name="username" v-model="state.name" />
      </div>

      <div class="login-row">
        <label for="pass">Password (8 characters minimum):</label>
        <input
          type="password"
          id="pass"
          name="password"
          minlength="8"
          required
          v-model="state.password"
        />
      </div>

      <button @click="handleLogin">Login</button>
    </div>
    <AdminView v-else></AdminView>
  </div>
</template>

<script setup>
import { reactive } from "vue";
import AdminView from "./components/AdminView.vue";
import backendAdress from "./../backendAdress";

const state = reactive({
  name: "",
  password: "",
  token: false,
});

function handleLogin(input) {
  var baseURL = backendAdress + "/";
  var url = baseURL + "login";
  fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      name: state.name,
      password: state.password,
    }),
  })
    .then((resp) => resp.json())
    .then(function (response) {
      if (response.ans === "No data") {
        console.log("Error");
      } else {
        state.token = response.ans;
      }
    });
  input.preventDefault();
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}

.login {
  display: flex;
  flex-direction: column;
  row-gap: 3rem;
  max-width: 300px;
}

.login-row {
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
}

.site-view {
  display: flex;
  justify-content: center;
}
</style>
