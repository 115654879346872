<template>
  <div class="admin-page-wrapper">
    <div class="time-select-wrapper">
      <div>
        <label for="start">Start date:</label>

        <input
          type="date"
          id="start"
          name="time-start"
          v-model="state.startTime"
        />
      </div>
      <div>
        <label for="end">End date:</label>

        <input type="date" id="end" name="time-end" v-model="state.endTime" />
      </div>
      <div>
        <button @click="handle_fetch_data">Fetch data</button>
      </div>
    </div>
    <div v-for="(order, index) in state.orders" :key="`order-${index}`">
      <!--    {{ JSON.parse(order.order) }} -->
      <div class="row">
        <div class="cell">{{ order.eurail_order_number }}</div>
        <div class="cell">
          {{ order.order.first_name + " " + order.order.surname }}
        </div>
        <div class="cell">
          {{ order.order.adress + " " + order.order.postal_code }}
        </div>
      </div>
      <div
        v-for="(ticket, index2) in [...order.order.order]"
        :key="`tickets-${index}-${index2}`"
      >
        <details class="row">
          <summary>
            <div class="grid-cell">Ticket - {{ index2 }}</div>
          </summary>
          <div class="key-value-grid">
            <template v-for="(value, key) in ticket" :key="`${key}-in-ticket`">
              <div class="grid-cell">{{ key }} :</div>
              <div class="grid-cell">{{ value }}</div>
            </template>
          </div>
        </details>
      </div>
    </div>

    <div>
      <div
        v-for="(value, key) in state.ticketCountObject"
        class="row"
        :key="`${key}-ticket-count`"
      >
        <div class="cell">{{ value.name }}</div>
        <div class="cell">{{ value.age }}</div>
        <div class="cell">{{ value.comfort }}</div>
        <div class="cell">{{ value.amount }}</div>
        <div class="cell">{{ value.EURprice * value.amount }}</div>
      </div>
    </div>
    <div><button @click="get_csv">Download CSV</button></div>
    {{ state.tempCsv }}
  </div>
</template>

<script setup>
import { onMounted, reactive } from "vue";
import backendAdress from "./../../backendAdress";

const state = reactive({
  orders: [],
  ticketCountObject: {},
  startTime: "",
  endTime: "",
  tempCsv: "",
});

async function getOrdersFromDb() {
  const url = backendAdress + "/get_order_in_timespan";
  let res = fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      start: state.startTime,
      end: state.endTime,
    }),
  })
    .then((resp) => resp.json())
    .then((data) => {
      if (data.ans.length < 1) {
        return [];
      }
      return data.ans;
    });
  return res;
}

function set_default_times() {
  var startDate = new Date();
  startDate.setMonth(startDate.getMonth() - 1);
  state.startTime = startDate.toISOString().split("T")[0];
  var endDate = new Date();
  state.endTime = endDate.toISOString().split("T")[0];
}

async function handle_fetch_data() {
  let orders = await getOrdersFromDb();

  orders.forEach((order) => {
    order.order = JSON.parse(order.order);
  });

  state.orders = orders;

  state.ticketCountObject = generate_ticket_object();
}

function generate_ticket_object() {
  var output = [];

  for (let i = 0; i < state.orders.length; i++) {
    var order_tickets = state.orders[i].order.order;
    for (let j = 0; j < order_tickets.length; j++) {
      var ticket = order_tickets[j];

      var is_in_array = output.find((el) => {
        return (
          el.name == ticket.name &&
          el.comfort == ticket.comfort &&
          el.age == ticket.age &&
          el.EURprice == ticket.EURprice
        );
      });

      if (is_in_array) {
        is_in_array.amount += 1;
      } else {
        var ticket_obj = {
          name: ticket.name,
          age: ticket.age,
          comfort: ticket.comfort,
          EURprice: ticket.EURprice,
          amount: 1,
        };

        output.push(ticket_obj);
      }
    }
  }

  return output;
}

function get_csv() {
  let csvContent = "";

  Object.keys(state.ticketCountObject[0]).forEach((key) => {
    csvContent += key + ",";
  });

  csvContent += "\n";

  state.ticketCountObject.forEach((obj) => {
    Object.values(obj).forEach((val) => {
      csvContent += val + ",";
    });
    csvContent += "\n";
  });

  state.tempCsv = csvContent;
  
  downloadBlob(csvContent, "export.csv", "text/csv;charset=utf-8;");
}

function downloadBlob(content, filename, contentType) {
  // Create a blob
  var blob = new Blob([content], { type: contentType });
  var url = URL.createObjectURL(blob);

  // Create a link to download it
  var pom = document.createElement("a");
  pom.href = url;
  pom.setAttribute("download", filename);
  pom.click();
}

onMounted(() => {
  set_default_times();

  handle_fetch_data();
});
</script>

<style>
.admin-page-wrapper {
  display: flex;
  flex-direction: column;
  row-gap: 2rem;
}

.time-select-wrapper {
  display: flex;
  column-gap: 1rem;
}

.row {
  display: flex;
  flex-direction: row;
  column-gap: 0.5rem;
  border: 1px solid black;
}

.cell {
  padding: 0.5rem;
}

.grid-cell {
  text-align: start;
}

.key-value-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: 0.5rem;
}

details > summary {
  list-style: none;
}

details summary::-webkit-details-marker {
  display: none;
}
</style>
